<!-- Dialog.vue -->
<template>
    <div v-if="visible" class="dialog-overlay">
      <div class="dialog">
        <h3>{{ title }}</h3>
        <p>{{ message }}</p>
        <input v-model="inputValue" type="text" class="dialog-input" />
        <div class="dialog-buttons">
          <button @click="confirm" class="dialog-button confirm">Confirm</button>
          <button @click="cancel" class="dialog-button cancel">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        title: '',
        message: '',
        inputValue: '',
        resolve: null,
        reject: null
      };
    },
    methods: {
      open({ title, message }) {
        this.title = title;
        this.message = message;
        this.visible = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      confirm() {
        this.resolve(this.inputValue);
        this.visible = false;
      },
      cancel() {
        this.reject();
        this.visible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .dialog {
    background: #2d2d2d;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .dialog h3 {
    margin: 0 0 10px;
    color: #ffffff;
  }
  .dialog p {
    margin: 0 0 20px;
    color: #cccccc;
  }
  .dialog-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #3a3a3a;
    color: #ffffff;
  }
  .dialog-buttons {
    display: flex;
    justify-content: space-between;
  }
  .dialog-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .dialog-button.confirm {
    background-color: #28a745;
    color: white;
  }
  .dialog-button.cancel {
    background-color: #dc3545;
    color: white;
  }
  </style>
  