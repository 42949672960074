<!-- views/Account.vue -->
<template>
    <div class="account-page">
      <h1>User Account View</h1>
      <UserProfile />
      <UserWallet />
    </div>
  </template>
  
  <script>
  import UserProfile from '../components/UserProfile.vue';
  import UserWallet from '../components/UserWallet.vue';
  
  export default {
    components: {
      UserProfile,
      UserWallet
    },
  };
  </script>
  
  <style scoped>
  .account-page {
    padding: 20px;
  }
  
  h1 {
    font-family: Arial, sans-serif;
  }
  </style>
  