<!-- login.vue -->
<template>
  <div class="login">
    <input type="button" v-if="isAuth" value="Logout" @click="logout">
    <input type="button" v-else value="Login" @click="login">
  </div>
</template>


<script>
import { ethers } from 'ethers';
import { mapMutations, mapGetters } from 'vuex';
import api from '../lib/api';
import socket from '../lib/socket';

export default {
  computed: {
    ...mapGetters(['isAuth', 'auth', 'wallet', 'isWallet', 'isProfile']),
  },
  mounted() {
  console.log('Is user', this.isAuth);
  if (this.isAuth) {
    socket.connect();
    console.log('isWallet', this.isWallet)
    if (!this.isWallet) {
      api.get('/wallet').then((response) => {
        console.log(response);
        this.setWallet(response.data);
      }).catch((error) => {
        console.log(error);
      });
    }
    console.log('isProfile', this.isProfile)
    if (!this.isProfile) {
      api.get('/user/profile').then((response) => {
        console.log(response);
        this.setProfile(response.data);
      }).catch((error) => {
        console.log(error);
      });
    }    
  }
},
  beforeDestroy() {
    socket.close();
  },  
  methods: {
    ...mapMutations(['setAuth', 'clearAuth', 'setWallet', 'clearWallet', 'setProfile', 'clearProfile']),
    async login() {
      try {
        if (!window.ethereum) {
          throw new Error('Ethereum wallet is not installed.');
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        // Step 1: Request nonce from backend
        const nonceResponse = await api.get('/auth/nonce', {
          params: { publicAddress: address }
        });
        const nonce = nonceResponse.data.nonce;

        // Step 2: Sign the nonce
        const messagePrefix = process.env.VUE_APP_SIGNATURE_MESSAGE || 'I am signing my one-time nonce:';
        const message = `${messagePrefix} ${nonce}`;
        console.log("Message:", message)
        const signedNonce = await signer.signMessage(message);

        // Step 3: Send the signed nonce to backend
        const loginResponse = await api.post('/auth/login', {
          publicAddress: address,
          signature: signedNonce
        });

        // Step 4: Store the JWT token received from the backend
        let token = loginResponse.data.token;
        console.log("JWT Token:", token);

        // Store token in Vuex
        this.setAuth(token);

        // Fetch and store wallet
        const walletResponse = await api.get('/wallet');
        this.setWallet(walletResponse.data);

        // Fetch and store wallet
        const profileResponse = await api.get('/user/profile');
        this.setProfile(profileResponse.data);

        this.$router.push('/account');

        socket.connect()

      } catch (error) {
        console.log(error);
      }
    },

    async logout() {
      this.clearAuth(); // Clear token in Vuex
      this.clearWallet(); // Clear wallet in Vuex
      socket.close()
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    }
  }
};

</script>

<style scoped></style>
