import Vue from 'vue'
import App from './App.vue'
import router from './lib/router'
import store from './lib/store'

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(Toast, {
  // Customize the toast options as needed
  position: 'bottom-right',
  timeout: 2000,
  hideProgressBar: true,
  closeOnClick: true,
});