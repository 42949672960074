<template>
  <div class="container">
    <h2>Wallet</h2>
    <table>
      <tbody>
        <tr v-for="(token, tokenId) in wallet" :key="tokenId" class="wallet-item">
          <td><img style='width:25px;height:25px;' :src="'images/' + tokenId + '.png'"></td>
          <td style="text-transform: uppercase;">
            <number :value="token.amount ? token.amount : 0"></number> {{ token.tokenName }}
          </td>
          <td>
            <button @click="deposit(token)" :disabled="false" class="deposit-button">Deposit</button>
            <button @click="withdraw(token)" :disabled="!token.withdrawEnabled"
              class="withdraw-button">Withdraw</button>
          </td>
        </tr>
      </tbody>
    </table>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Number from './AnimateNumber.vue';
import { ethers } from 'ethers';
import Dialog from './Dialog.vue';

export default {
  components: {
    number: Number,
    Dialog
  },
  computed: {
    ...mapGetters(['wallet', 'token']),
  },
  methods: {
    async getChainId() {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        console.log('Current chainId', network.chainId);
        return network.chainId;
      } catch (error) {
        console.error('Error getting network:', error);
        throw error;
      }
    },
    async deposit(token) {
      console.log('Deposit', token);
      try {
        const chainId = await this.getChainId();
        if (chainId === token.chainId) {
          const amount = await this.$refs.dialog.open({
            title: 'Deposit Amount',
            message: `Enter amount to deposit for ${token.tokenName}:`
          });

          if (amount) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []); 
            const signer = provider.getSigner();
            const address = await signer.getAddress(); 
            console.log('Address:', address);

            const value = ethers.utils.parseUnits(amount, token.tokenUnit || 'ether'); 

            if (token.nativeToken) {
              const tx = {
                to: token.toAddress, 
                value: value
              };
              const transaction = await signer.sendTransaction(tx);
              console.log('Transaction:', transaction);
              this.$toast.success('Transaction sent! Waiting for confirmation...');
              await transaction.wait();
              this.$toast.success('Transaction confirmed!');

            } else {
              const contract = new ethers.Contract(token.contractAddress, [
                "function transfer(address to, uint256 amount) returns (bool)"
              ], signer);

              const tx = await contract.populateTransaction.transfer(token.toAddress, value);

              const gasLimit = await contract.estimateGas.transfer(token.toAddress, value);

              const transaction = await signer.sendTransaction({
                to: contract.address,
                data: tx.data,
                gasLimit: gasLimit,
              });

              console.log('Transaction:', transaction);
              this.$toast.success('Transaction sent! Waiting for confirmation...');
              await transaction.wait();
              this.$toast.success('Transaction confirmed!');
            }
          }
        } else {
          this.$toast.error('Please switch to the correct network in MetaMask.');
        }
      } catch (error) {
        console.error('Error during deposit:', error);
        this.$toast.error('Error during deposit. Check console for details.');
      }
    },
    withdraw(token) {
      console.log('Withdraw', token);
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #2d2d2d;
  /* Match background color */
  color: #ffffff;
  /* Match text color */
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

h2 {
  color: #ffffff;
  /* Match header color */
}

table {
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #444444;
  /* Match table row border color */
}

.wallet-item {
  background-color: #3a3a3a;
  /* Match table row background color */
}

.withdraw-button {
  background-color: #007bff;
  /* Match withdraw button color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.withdraw-button:disabled {
  background-color: #6c757d;
  /* Match disabled button color */
  cursor: not-allowed;
}

.deposit-button {
  background-color: #28a745;
  /* Match deposit button color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 5px;
}

.deposit-button:disabled {
  background-color: #6c757d;
  /* Match disabled button color */
  cursor: not-allowed;
}
</style>
