<!-- UserProfile.vue -->
<template>
    <div class="container">
      <h2>User Profile</h2>
      <div v-if="profile">
        <p><strong>Public Address:</strong> {{ profile.publicAddress }}</p>
        <p><strong>Registered At:</strong> {{ profile.createdAt }}</p>
      </div>
      <div v-else>
        <p>Loading profile data...</p>
      </div>
    </div>
  </template>
  

<script>
// UserProfile.vue
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['profile']),
  },
};
</script>

<style scoped>
  .container {
    background-color: #2d2d2d; /* Match background color */
    color: #ffffff; /* Match text color */
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
</style>