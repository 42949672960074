// api.js
import axios from 'axios';
import store from './store';
import router from './router'; // Import your router instance
import Vue from 'vue';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const auth = store.getters.auth;
    if (auth) {
      config.headers.Authorization = `Bearer ${auth}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response, // Return the response as is if successful
  (error) => {

    const errorCode = error.response?.data?.code;
    const errorMessage = error.response?.data?.message || 'An unexpected error occurred';

    if (errorCode === 'invalid_token') {
      store.commit('clearAuth'); // Clear the auth if invalid
      if (router.currentRoute.path !== '/') {
        router.push('/');
      }
    } else {
      console.log('Error:', errorMessage);
      Vue.$toast.error(errorMessage);
    }

    return Promise.reject(error); // Reject the error to handle it in the calling function

  }
);

export default api;
